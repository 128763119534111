import { Controller } from '@hotwired/stimulus'
import * as echarts from 'echarts/core'
import { DatasetComponent, GridComponent, LegendComponent, TooltipComponent, MarkAreaComponent } from 'echarts/components'
import { BarChart, GaugeChart, LineChart, PieChart, SankeyChart, ScatterChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

import 'echarts/i18n/langDE.js'
import colors from '@quis-de/tailwindcss-config/src/colors'
import MorphReconnect from '../../javascript/mixins/morph_reconnect'
import {
  numberToCurrency,
  numberToCurrencyPerArea,
  numberToDays,
  numberToHuman, numberToPercentage
} from '../../javascript/helpers/number_helper'

export { colors }

echarts.use([
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  MarkAreaComponent,
  BarChart,
  GaugeChart,
  LineChart,
  PieChart,
  ScatterChart,
  SankeyChart,
  CanvasRenderer
])

export class BaseChartController extends MorphReconnect(Controller) {
  static values = {
    title: { type: String, default: '' },
    pdf: { type: Boolean, default: false },
    customColors: { type: Boolean, default: false },
    locale: { type: String, default: 'DE' },
    tooltipFormatter: { type: Object, default: {} },
    tooltipValueFormatter: { type: String, default: 'value' },
    legendType: { type: String, default: 'none' },
    series: Array
  }

  colors = this.customColorsValue ? [colors.purple[5], colors.purple[2]] : Object.values(colors.chart)

  connect () {
    this.chart = echarts.init(this.element, null, {
      locale: this.localeValue
    })
    this.chart.setOption(this.options())
    this.chart.on('click', this.handleClick)

    this.bindResize = this.resize.bind(this)
    window.addEventListener('resize', this.bindResize)
  }

  disconnect () {
    window.removeEventListener('resize', this.bindResize)
    this.chart.dispose()
  }

  resize () {
    if (this.chart !== null && this.chart !== undefined) {
      this.chart.resize()
    }
  }

  options () {
    let tooltip

    if (!this.pdfValue) {
      tooltip = this.tooltipOptions()
    }

    return {
      title: this.titleOptions(),
      textStyle: defaultTextStyle,
      color: this.colors,
      animation: false,
      legend: this.legend(),
      tooltip
    }
  }

  titleOptions () {
    return {
      show: this.hasTitleValue,
      text: this.titleValue,
      textStyle: defaultTextStyle
    }
  }

  legend () {
    return this.legendOptions()[this.legendTypeValue]
  }

  legendOptions () {
    return {
      none: {
        show: false
      },
      left: {
        orient: 'vertical',
        top: 'top',
        left: 0,
        itemHeight: 14,
        textStyle: defaultTextStyle
      },
      vertical: {
        orient: 'vertical',
        top: 'center',
        right: 0,
        itemHeight: 14,
        textStyle: defaultTextStyle
      },
      horizontal: {
        orient: 'horizontal',
        bottom: 0,
        itemHeight: 14,
        textStyle: defaultTextStyle
      },
      horizontal_two_lines: {
        orient: 'vertical',
        bottom: 0,
        itemHeight: 14,
        textStyle: defaultTextStyle,
        height: 40
      }
    }
  }

  tooltipOptions () {
    return {
      trigger: 'item',
      valueFormatter: tooltipValueFormatter(this.localeValue, this.tooltipValueFormatterValue),
      formatter: this.tooltipFormatter()
    }
  }

  handleClick (params) {
    if (params.data.href !== undefined) {
      // eslint-disable-next-line no-undef
      Turbo.visit(params.data.href)
    }
  }

  tooltipFormatter () {
    return undefined
  }
}

export const defaultTextStyle = {
  color: colors.gray.active,
  fontSize: 14,
  fontFamily: 'Lato, sans-serif'
}

export const defaultBoldTextStyle = {
  ...defaultTextStyle,
  fontWeight: 'bold',
  fontSize: 16
}

export function tooltipValueFormatter (locale, type, options = undefined) {
  return {
    value: (value) => numberToHuman(value, locale),
    days: (value) => numberToDays(value, locale),
    currency: (value) => numberToCurrency(value, locale),
    currency_by_area: (value) => numberToCurrencyPerArea(value, locale),
    percentage: (value) => numberToPercentage(value, locale, options)
  }[type]
}

export default {
  BaseChartController,
  colors,
  defaultTextStyle,
  defaultBoldTextStyle,
  tooltipValueFormatter
}
