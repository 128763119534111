import { tooltipValueFormatter } from './base_chart_component_controller'
import { XYChartController } from './xy_chart_component_controller'

export default class extends XYChartController {
  seriesType () {
    return 'line'
  }

  tooltipFormatter () {
    return {
      delta: (params) => this.deltaFormatter(params)
    }[this.tooltipFormatterValue.formatter]
  }

  deltaFormatter (params) {
    return `<div style="margin: 0px 0 0;line-height:1;">
              <div style="margin: 0px 0 0;line-height:1;">
                <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${params[0].axisValueLabel}</div>
                <div style="margin: 10px 0 0;line-height:1;">
                  ${params.map((series, i) => this.deltaFormatSeries(series, i)).join('')}
                  <div style="clear:both"/>
                </div>
                <div style="clear:both"/>
              </div>
              <div style="clear:both"/>
            </div>`
  }

  deltaFormatSeries (series, index) {
    const y = tooltipValueFormatter(this.localeValue, this.tooltipFormatterValue.yFormatter || 'value')(series.value.y)
    let delta
    if (series.dataIndex > 11) {
      const actualY = series.value.y
      const prevY = this.seriesValue[series.seriesIndex].data[series.dataIndex - 12].y
      delta = ((actualY - prevY) / prevY)

      delta = tooltipValueFormatter(this.localeValue, this.tooltipFormatterValue.deltaFormatter || 'percentage', { maximumFractionDigits: 2 })(delta)
    }
    const n = tooltipValueFormatter(this.localeValue, this.tooltipFormatterValue.nFormatter || 'value')(series.value.n)
    return `<div style="margin: ${index === 0 ? 0 : 10}px 0 0;line-height:1;">
              <div style="margin: 0px 0 0;line-height:1;">
                ${series.marker}
                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${series.seriesName}</span>
                <span style="float:right;margin-left:20px;font-size:14px;color:#666">
                  <span style="font-weight:900">${y}</span>
                  (${delta !== undefined ? `${delta} zum Vorjahr, ` : ''}n = ${n})
                </span>
                <div style="clear:both"></div>
              </div>
            </div>`
  }
}
