import { Controller } from '@hotwired/stimulus'
import * as L from 'leaflet/dist/leaflet-src.esm'
import { GestureHandling } from '@gstat/leaflet-gesture-handling'
import colors from '@quis-de/tailwindcss-config/src/colors'

import Marker from './map/marker'
import MorphReconnect from '../../javascript/mixins/morph_reconnect'

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

export default class extends MorphReconnect(Marker(Controller)) {
  static targets = ['map', 'form', 'coordinate']
  static values = {
    coordinate: Array,
    iconHtml: String,
    maptilerKey: String,
    zoom: { type: Number, default: 15 },
    layer: { type: String, default: 'standard' },
    dragableMarker: { type: Boolean, default: false },
    interactable: { type: Boolean, default: false },
    controlable: { type: Boolean, default: false },
    zoomToFit: { type: Array, default: ['geojson'] },
    additionalMarkers: { type: Array, default: [] },
    tooltipFormatter: { type: Object, default: {} },
    geojson: { type: [Object, undefined], default: null },
    perimeter: { type: Number, default: 0 }
  }

  _defaultAttribution = '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
  _layers = new Map([
    [
      'standard',
      this._createLayer([{
        url: `https://api.maptiler.com/maps/efc6055f-5476-4eaf-a890-36be6226d35e/{z}/{x}/{y}.png?key=${this.maptilerKeyValue}`,
        attribution: this._defaultAttribution
      }])
    ], [
      'infrastructure',
      this._createLayer([{
        url: `https://api.maptiler.com/maps/2eea23bd-49b9-4e02-9957-db5207f83dc9/{z}/{x}/{y}.png?key=${this.maptilerKeyValue}`,
        attribution: this._defaultAttribution
      }])
    ], [
      'neighbourhood',
      this._createLayer([{
        url: `https://api.maptiler.com/maps/cb5462c6-ef16-4e0d-b31b-5a2db4ffa9d0/{z}/{x}/{y}.png?key=${this.maptilerKeyValue}`,
        attribution: this._defaultAttribution
      }])
    ],
    [
      'satelite',
      this._createLayer([{
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        attribution: '<a href="https://www.arcgis.com/home/item.html?id=10df2279f9684e4a9f6a7f08febac2a9" target="_blank">© Esri World Imagery</a>'
      }, {
        url: `https://api.maptiler.com/maps/1ca46397-08cb-4fbc-b2a3-a87b77c71a8c/{z}/{x}/{y}.png?key=${this.maptilerKeyValue}`,
        attribution: this._defaultAttribution
      }])
    ]
  ])

  connect () {
    this.featureGroup = L.featureGroup([])
    this.map = L.map(this.mapTarget, {
      preferCanvas: true,
      minZoom: 1,
      maxZoom: 18,
      dragging: this.interactableValue,
      doubleClickZoom: this.interactableValue,
      zoomControl: false,
      touchZoom: this.interactableValue,
      scrollWheelZoom: this.interactableValue,
      gestureHandling: this.interactableValue
    })

    this._addLayersToMap(this.layerValue)
    this._setView()
    this.addGeoJSON()
    this.addPerimeter()
    this._fitBounds()
    this._addControls()

    this.bindResize = this.resize.bind(this)
    window.addEventListener('resize', this.bindResize)
    super.connect()
  }

  disconnect () {
    this.map.remove()
    window.removeEventListener('resize', this.bindResize)
  }

  resize () {
    this._setView()
    this._fitBounds()
  }

  _setView () {
    this.map.setView(this.coordinateValue, this.zoomValue)
  }

  _fitBounds () {
    if (this.featureGroup.getLayers().length) {
      this.map.fitBounds(this.featureGroup.getBounds())
    }
  }

  _addLayerToMap () {
    L.tileLayer(this._availableLayers[this.layerValue], {
      attribution:
        '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      tileSize: 512,
      zoomOffset: -1,
      crossOrigin: true
    }).addTo(this.map)
  }

  _createLayer (layers) {
    return L.layerGroup(layers.map(layer => {
      return L.tileLayer(layer.url, {
        attribution: layer.attribution,
        tileSize: 512,
        zoomOffset: -1,
        crossOrigin: true
      })
    }))
  }

  _addLayersToMap (defaultLayer) {
    this._layers.get(defaultLayer).addTo(this.map)
  }

  addGeoJSON () {
    try { this.geojsonValue } catch (e) { return } // eslint-disable-line no-unused-expressions

    const geojson = L.geoJSON(this.geojsonValue, {
      style: { color: colors.secondary, opacity: 0.7 }
    }).addTo(this.map)

    if (this.zoomToFitValue.includes('geojson')) this.featureGroup.addLayer(geojson)
  }

  addPerimeter () {
    if (this.perimeterValue <= 0) { return } // eslint-disable-line no-unused-expressions

    const circle = L.circle(this.coordinateValue, {
      radius: this.perimeterValue, color: colors.secondary, opacity: 0.7
    }).addTo(this.map)

    if (this.zoomToFitValue.includes('geojson')) this.featureGroup.addLayer(circle)
  }

  _addControls () {
    if (!this.controlableValue) {
      return
    }

    L.control.zoom().addTo(this.map)
    L.control.scale({ metric: true, imperial: false }).addTo(this.map)
    L.control.layers({
      Standard: this._layers.get('standard'),
      Satelit: this._layers.get('satelite')
    }, null, { position: 'bottomright' }).addTo(this.map)
  }
}
