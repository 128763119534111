import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import { BaseChartController } from './base_chart_component_controller'

export class PieChartController extends BaseChartController {
  static values = merge(cloneDeep(BaseChartController.values), {
    series: Object,
    radius: { type: Number, default: 80 }
  })

  options () {
    let borderColor = '#fff'
    if (this.pdfValue) {
      this.legendTypeValue = 'pieLeft'
      borderColor = '#f2f2f2'
    } else {
      this.legendTypeValue = 'none'
    }

    return merge(super.options(), {
      series: {
        itemStyle: {
          borderRadius: 5,
          borderColor,
          borderWidth: 2
        },
        left: this.pdfValue ? 160 : 0,
        type: 'pie',
        radius: this.pdfValue ? '100%' : `${this.radiusValue}%`,
        label: {
          show: !this.pdfValue
        },
        data: this.transformToSeriesData(this.seriesValue)
      }
    })
  }

  transformToSeriesData (series) {
    return Array.from(Object.entries(series).map(([name, value]) => {
      return {
        name,
        value
      }
    }))
  }

  legendOptions () {
    const data = this.transformToSeriesData(this.seriesValue)
    const valueFormatter = this.tooltipOptions().valueFormatter

    return merge(super.legendOptions(), {
      pieLeft: merge(super.legendOptions().left, {
        formatter: (name) => {
          let value = data.filter(row => row.name === name)[0].value
          if (valueFormatter !== undefined) {
            value = valueFormatter(value)
          }
          return `${name}\n{bold|${value}}`
        },
        textStyle: {
          rich: {
            bold: {
              fontWeight: 700,
              lineHeight: 26
            }
          }
        }
      })
    })
  }
}

export default PieChartController
