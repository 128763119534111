import AutoSubmit from '@stimulus-components/auto-submit'

export default class extends AutoSubmit {
  static targets = ['url']
  static values = {
    updateUrl: { type: Boolean, default: false },
    reloadId: { type: String }
  }

  connect () {
    super.connect()
    if (this.reloadIdValue) {
      this.bindReloadFrameWithId = this.reloadFrameWithId.bind(this)
      this.element.addEventListener('turbo:submit-end', this.bindReloadFrameWithId)
    }
  }

  disconnect () {
    if (this.reloadIdValue) { this.element.removeEventListener('turbo:submit-end', this.bindReloadFrameWithId) }

    super.disconnect()
  }

  submit () {
    if (this.updateUrlValue) { this.updateHistory() }
    super.submit()
  }

  updateHistory () {
    const url = new URL(this.element.action)

    this.urlTargets.forEach((elem) => {
      if (elem.type !== 'radio' || elem.checked) {
        if (elem.value) { url.searchParams.append(elem.name, elem.value) }
      }
    })

    // eslint-disable-next-line no-undef
    history.replaceState({}, null, url.toString())
  }

  reloadFrameWithId () {
    document.getElementById(this.reloadIdValue).reload()
  }
}
